import React from "react"
import Layout from "../components/Layout"
import PageTitle from "../components/PageTitle"
import ContentBlock from "../components/ContentBlock"

const PageRefundPolicy = () => (
  <Layout>
    <PageTitle title="Refund Policy"></PageTitle>
    <ContentBlock className="rich-text">
      <p>
        To ensure 100% customer satisfaction, we abide by the following in
        regards to payments and refunds.
      </p>
      <ul>
        <li>
          Any company evaluating Towbook can do so for 30 days without paying or
          providing any payment information.
        </li>
        <li>Towbook does not charge any setup or cancellation fees.</li>
        <li>
          Towbook does not lock people into any contracts. Customers are able to
          stop the service at anytime.
        </li>
        <li>
          Towbook is paid for on a month-to-month basis.{" "}
          <strong>Monthly charges are not refundable.</strong>
        </li>
      </ul>
      <p>
        If you decide Towbook isn&apos;t right for your company, regardless of
        the reason, simply contact us and we will stop billing you so that you
        do not receive any further charges from us.
      </p>
      <h3>Additional Questions?</h3>
      <p>
        We want you to be more than comfortable working with Towbook -- both
        with the product itself, and our billing process. If you have additional
        questions, please call us at{" "}
        <a href="tel:+18103205063">(810) 320-5063</a>, or email{" "}
        <a href="mailto:support@towbook.com">support@towbook.com</a>.
      </p>
    </ContentBlock>
  </Layout>
)

export default PageRefundPolicy
